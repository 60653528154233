export const environment = {
  production: false,
  apiBase: 'https://londonhydro-duom-dev.web.app/api/v1',
  firebaseConfig: {
    apiKey: 'AIzaSyA3v0dXgENMU9fQGMQkOeJrfMApx2Rc6Js',
    authDomain: 'londonhydro-duom-dev.firebaseapp.com',
    projectId: 'londonhydro-duom-dev',
    storageBucket: 'londonhydro-duom-dev.appspot.com',
    messagingSenderId: '224816520098',
    appId: '1:224816520098:web:d58f0236d8b15df91230fb',
    measurementId: 'G-DMFD2F16BE'
  }
};