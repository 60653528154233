import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import firebase from 'firebase/compat/app';
import { CredentialResponse, PromptMomentNotification } from 'google-one-tap';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { IUserDetails } from '../models/userDetails.model';
import { Router } from '@angular/router';
import { ToastController, Platform } from '@ionic/angular';
import { SocialLogin } from '@capgo/capacitor-social-login';
// import { Auth, signInWithPopup, GoogleAuthProvider } from '@angular/fire/auth';
import { Capacitor } from '@capacitor/core';
import { getAuth, signInWithCredential, GoogleAuthProvider } from "firebase/auth";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GoogleSSOAuthService {
  googleUser: any = '';
  currentTokens: any;
  constructor(
    private authfa: AngularFireAuth, 
    private angularFirestore: AngularFirestore, 
    private router: Router,
    private toastController: ToastController,
    private platform: Platform) { 
      this.gInitialize();
  }
  
  async gInitialize() {
    if (this.platform.is('ios')) {
      SocialLogin.initialize({
        google: {
          webClientId: '116213566452-11nvi12d6m3teu99h3rktmupdp163fr8.apps.googleusercontent.com',
          iOSClientId: '116213566452-6p9sro77pm6fg0j25t2o8ragdvuhl83a.apps.googleusercontent.com',
          iOSServerClientId: '116213566452-11nvi12d6m3teu99h3rktmupdp163fr8.apps.googleusercontent.com',
          // mode: 'offline' // this is for accessing the app offline
        }
      }); 
    } else {
      SocialLogin.initialize({
        google: {
          webClientId: '116213566452-11nvi12d6m3teu99h3rktmupdp163fr8.apps.googleusercontent.com',
          // mode: 'offline' // this is for accessing the app offline
        }
      }); 
    }
       
  }

  async googleSSO() {
    try {
      this.googleUser = await SocialLogin.login({
        provider: 'google',
        options: {}
      });
      this.firebaseSignIn(this.googleUser.result.idToken);
    } catch (error) {
      console.error('Google Sign-In Error:', error);
      throw error;
    }
  }

  // async exchangeAuthCode(serverAuthCode: string) {
  //   const tokenEndpoint = 'https://oauth2.googleapis.com/token';
    
  //   const body = new URLSearchParams();
  //   body.append('client_id', '116213566452-11nvi12d6m3teu99h3rktmupdp163fr8.apps.googleusercontent.com');
  //   body.append('client_secret', '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');
  //   body.append('code', serverAuthCode);
  //   body.append('grant_type', 'authorization_code');
  //   body.append('redirect_uri', 'http://localhost:8100/auth/login'); 
  
  //   try {
  //     const response = await fetch(tokenEndpoint, {
  //       method: 'POST',
  //       headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  //       body: body.toString(),
  //     });
  
  //     const data = await response.json();
  //     console.log("Google Token Response:", data); // Debugging
  
  //     if (!data.id_token) {
  //       throw new Error("ID Token not received");
  //     }
  
  //     return data; // Returns { id_token, access_token, refresh_token }
  //   } catch (error) {
  //     console.error("Error exchanging auth code:", error);
  //     return null;
  //   }
  // }
  

  async firebaseSignIn(idToken: string) {
    const auth = getAuth();
    const credential = GoogleAuthProvider.credential(idToken);
    try {
      const userCredential = await signInWithCredential(auth, credential);
      this.checkUserAndAddUID(userCredential.user.uid, userCredential.user.email);
    } catch (error) {
      console.error('Firebase sign-in error:', error);
      throw error;
    }
  }

  async checkUserAndAddUID(uid: string, email: string): Promise<void> {
    debugger;
    const userRef = this.angularFirestore.collection<IUserDetails>('users').doc(uid);
    const userDoc = await userRef.get().toPromise();

    if (userDoc.exists) {
      this.userRoleBasedAccess();
    } else {
      this.temporaryCodeForAddingUid(userRef, uid, email); // Need to be removed after Uid has been aded to all the User account.
      // this.showToast('Please contact administrator to access this application.');
    }
  }
  
  async temporaryCodeForAddingUid(userRef, uid, email) {
    const emailQuery = this.angularFirestore.collection<IUserDetails>('users', (ref) => ref.where('email', '==', email)).get();
      const emailQuerySnapshot = await emailQuery.toPromise();
      if (!emailQuerySnapshot.empty) {
        const userDoc = emailQuerySnapshot.docs[0];
        userRef.set({ ...userDoc.data(), uid: uid }, { merge: true })
          .then(() => {
            console.log('UID added to existing user document');
          })
          .catch((error) => {
            // console.error('Error adding UID to user document: ', error);
          });
      } 
      else {
        this.showToast('Please contact administrator to access this application.');
      }
  }
  

  async refresh() {
    // const authCode = await GoogleAuth.refresh();
  }

  checkLoggedIn() {
    // GoogleAuth.refresh()
    //   .then((data) => {
    //       if (data.accessToken) { this.currentTokens = data;}})
    //   .catch((error) => {
    //       if (error.type === 'userLoggedOut') {this.googleSSO()}
    //   });
  }
  
  async signOut() {
    if (this.platform.is('ios') || this.platform.is('android')) {
      // await GoogleAuth.signOut();
    }
    return this.authfa.signOut();
  }

  userRoleBasedAccess() {
    this.authfa.onAuthStateChanged(async (user) => {
      if (user) {
        let userObservable = this.angularFirestore.collection<IUserDetails>('users', (ref) =>
          ref.where('email', '==', user.email)
        ).valueChanges();
        let userSub = userObservable.subscribe((users) => {
          // if(users[0].LogInDateAndTime == undefined) { this.updateUserDetails(user.uid, {LogInDateAndTime: firebase.firestore.Timestamp.fromDate(new Date()) }); }
          if(users[0].viewIncidents == undefined) { this.updateUserDetails(user.uid, {viewIncidents: true}); }
          if(users[0].viewEasy == undefined) { this.updateUserDetails(user.uid, {viewEasy: false}); }
          if(users[0].viewHours == undefined) { this.updateUserDetails(user.uid, {viewHours: true}); }
          if(users[0].viewLogPresence == undefined) { this.updateUserDetails(user.uid, {viewLogPresence: true}); }
          if(users[0].viewDepartments == undefined) { this.updateUserDetails(user.uid, {viewDepartments: true}); }
          if(users[0].viewOutages == undefined) { this.updateUserDetails(user.uid, {viewOutages: false}); }
          if(users[0].role == 'duom_user' || users[0].role == 'duom_supervisor' || users[0].role == 'duom_admin' || users[0].role == 'duom_operator') 
          {
            this.router.navigate(['/tabs/home']);
          } else {
            this.showToast('Please contact administrator to access this application.');
          }
        });
      }
    });
  }

  updateUserDetails(userId, obj) {
    this.angularFirestore.collection('users').doc(userId).update(obj);
  }

  async showToast(msg) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 1500,
      position: 'bottom',
      color: 'dark',
    });
    await toast.present();
  }
}
