/* eslint-disable max-len */
import { Component, OnInit, OnDestroy } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { LocalNotifications } from '@ionic-native/local-notifications/ngx';
import { Observable } from 'rxjs';
import { IPresence } from './log-presence/shared/log-presence.model';
// import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { Subscription } from 'rxjs';
import { PopoverController } from '@ionic/angular';
import { AddmenuComponent } from './shared/components/addmenu/addmenu.component';
import { IUserDetails } from './shared/models/userDetails.model';
import { GoogleSSOAuthService } from './shared/services/google-sso.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  public logPresencesObservable: Observable<IPresence[]>;
  versionNumber = '';
  displayName: string;
  role: string;
  isSignedIn: boolean;
  logPresenceSub: Subscription;
  userDetails: any;
  userSub: any;
  userObservable: any;
  viewEasy: any = false;
  viewHours: any = true;
  viewIncidents: any = true;
  viewLogPresence: any = true;
  viewDepartments: any = true;
  viewOutages: any = false;
  viewAdministation: any = false;
  viewDamageAssessment: boolean;
  viewTailBoard: boolean;
  isDamageAssessment: boolean;
  isTailBoard: boolean;
  profileImage: string;

  constructor(
    public angularFirestore: AngularFirestore,
    private auth: AngularFireAuth,
    private router: Router,
    private appVersion: AppVersion,
    private localNotifications: LocalNotifications,
    // private screenOrientation: ScreenOrientation,
    public popoverController: PopoverController,
    private googleSSOAuthService: GoogleSSOAuthService
  ) {
    this.googleSSOAuthService.checkLoggedIn();
    this.auth.onAuthStateChanged((user) => {
      if (user) {
        this.profileImage = user.photoURL;
        this.displayName = user.displayName;
        const employeeId = user.email.split('@')[0];
        this.logPresencesObservable = this.angularFirestore
          .collection<IPresence>('log-presences', (ref) =>
            ref
              .where('employeeId', '==', employeeId)
              .orderBy('dateCreated', 'desc')
          )
          .valueChanges({ idField: 'id' });

        this.logPresenceSub = this.logPresencesObservable.subscribe(
          (logPresences) => {
            // Only works in iOS/Android devices not web
            if (logPresences && logPresences.length !== 0) {
              // when there is active log presence and signing in
              if (logPresences[0].secondsLeft > 0 && this.isSignedIn) {
                this.scheduleNotification(logPresences[0]);
              }

              // when adding more time or exiting the log or signing out, cancel all scheduled notificaitons
              if (
                logPresences[0].secondsLeft === 0 ||
                logPresences[0].secondsLogged ||
                logPresences[0].secondsAdded !== 0 ||
                !this.isSignedIn
              ) {
                this.localNotifications.cancelAll();
              }
            }
          }
        );
      }
    });
    this.commonDetails();
  }
  ngOnDestroy(): void {
    if (this.logPresenceSub) {
      this.logPresenceSub.unsubscribe();
    }
  }

  ngOnInit() {
    this.auth.onAuthStateChanged(async (user) => {
      if (user) {
        this.localNotifications.requestPermission(); // Only works in iOS/Android devices not web
        this.isSignedIn = true;
        this.userObservable = this.angularFirestore.collection<IUserDetails>('users', (ref) =>
          ref.where('email', '==', user.email)
        ).valueChanges();

        this.userSub = this.userObservable.subscribe((users) => {
          if (users[0].viewEasy != undefined) { this.viewEasy = users[0].viewEasy; }
          if (users[0].viewHours != undefined) { this.viewHours = users[0].viewHours; }
          if (users[0].viewIncidents != undefined) { this.viewIncidents = users[0].viewIncidents; }
          if (users[0].viewLogPresence != undefined) { this.viewLogPresence = users[0].viewLogPresence; }
          if (users[0].viewDepartments != undefined) { this.viewDepartments = users[0].viewDepartments; }
          this.viewDamageAssessment = true;
          this.viewTailBoard = true;
          // if (users[0].viewDamageAssessment !== undefined) { this.viewDamageAssessment = users[0].viewDamageAssessment; }
          if (users[0].viewOutages != undefined) { this.viewOutages = users[0].viewOutages; }
          this.viewAdministation = (users[0].role == 'duom_admin') ? true : false;

          if(users[0].role == 'duom_admin'){
            this.role =  'Admin';
          } else if(users[0].role == 'duom_supervisor'){
            this.role =  'Supervisor';
          } else if(users[0].role == 'duom_operator'){
            this.role = 'Operator'
          } else if(users[0].role == 'duom_director'){
            this.role = 'Director'
          } else {
            this.role = 'User'
          }
        });

      } else {
        this.isSignedIn = false;
      }
    });
    this.appVersion.getVersionNumber().then((versionNumber) => {
      if (versionNumber) {
        this.versionNumber = `V2021.${versionNumber}`; // Only works in iOS/Android devices not web
      }
    });
  }

  async signOut() {
    this.auth.onAuthStateChanged(async (user) => {
      if (user) {
        let lRObservable = this.angularFirestore.collection<IUserDetails>('users', (ref) =>
          ref.where('email', '==', user.email)
        ).valueChanges();
        let userSub = lRObservable.subscribe((users) => {
          if(users[0].lRApiKey != undefined || users[0].lRApiSecret != undefined) {
            users[0].lRApiKey = '';
            users[0].lRApiSecret = '';
            this.angularFirestore.collection('users').doc(user.uid).update(users[0]);
          }
          // if(users[0].LogInDateAndTime != undefined) {
          //   users[0].LogInDateAndTime = null;
          //   this.angularFirestore.collection('users').doc(user.uid).update(users[0]);
          // }
        });
      }
    });
    this.auth
      .signOut()
      .then(() => {
        console.log('user is signed out');
      })
      .catch((err) => console.log('Error ', err))
      .finally(() => {
        this.router.navigate(['/']).then(() => window.location.reload());
      });
  }

  scheduleNotification(logPresence: IPresence) {
    const firstTriggerTimeInS = (logPresence.dateExited as any).seconds;
    this.localNotifications.schedule([
      {
        id: 1,
        title: 'WARNING',
        text: `Employee ${logPresence.employeeId} is about to be OVERDUE at ${logPresence.location}. Please LOG OUT or extend time.`,
        trigger: { at: new Date(firstTriggerTimeInS * 1000) },
        vibrate: true,
        foreground: true,
        lockscreen: true,
      },
      {
        id: 2,
        title: 'WARNING',
        text: `Employee ${logPresence.employeeId} is about to be OVERDUE at ${logPresence.location}. Please LOG OUT or extend time.`,
        trigger: {
          at: new Date(new Date(firstTriggerTimeInS * 1000).getTime() + 60000),
        },
        vibrate: true,
        foreground: true,
        lockscreen: true,
      },
      {
        id: 3,
        title: 'WARNING',
        text: `Employee ${logPresence.employeeId} is about to be OVERDUE at ${logPresence.location}. Please LOG OUT or extend time.`,
        trigger: {
          at: new Date(new Date(firstTriggerTimeInS * 1000).getTime() + 120000),
        },
        vibrate: true,
        foreground: true,
        lockscreen: true,
      },
      {
        id: 4,
        title: 'WARNING',
        text: `Employee ${logPresence.employeeId} is about to be OVERDUE at ${logPresence.location}. Please LOG OUT or extend time.`,
        trigger: {
          at: new Date(new Date(firstTriggerTimeInS * 1000).getTime() + 180000),
        },
        vibrate: true,
        foreground: true,
        lockscreen: true,
      },
      {
        id: 5,
        title: 'WARNING',
        text: `Employee ${logPresence.employeeId} is about to be OVERDUE at ${logPresence.location}. Please LOG OUT or extend time.`,
        trigger: {
          at: new Date(new Date(firstTriggerTimeInS * 1000).getTime() + 240000),
        },
        vibrate: true,
        foreground: true,
        lockscreen: true,
      },
    ]);
  }

  async onShowAddmenu(ev) {
    const popover = await this.popoverController.create({
      component: AddmenuComponent,
      cssClass: 'popover-menu',
      event: ev,
      translucent: true,
      showBackdrop: false,
      componentProps: {
        onClick: () => {
          popover.dismiss();
        },
      },
    });
    await popover.present();
    await popover.onDidDismiss();
  }

  commonDetails() {
    this.angularFirestore.collection('commonDetails').doc('dynamicVar').valueChanges().subscribe(async (data: any) => {
      this.isDamageAssessment = data.damageAssessment;
      this.isTailBoard = data.tailBoard;
    });
  }
}
